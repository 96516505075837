exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-case-studies-loara-accessibility-tsx": () => import("./../../../src/pages/case-studies/loara/accessibility.tsx" /* webpackChunkName: "component---src-pages-case-studies-loara-accessibility-tsx" */),
  "component---src-pages-case-studies-loara-tsx": () => import("./../../../src/pages/case-studies/loara.tsx" /* webpackChunkName: "component---src-pages-case-studies-loara-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-overviews-dev-notes-tsx": () => import("./../../../src/pages/overviews/dev-notes.tsx" /* webpackChunkName: "component---src-pages-overviews-dev-notes-tsx" */),
  "component---src-pages-overviews-owgtowg-tsx": () => import("./../../../src/pages/overviews/owgtowg.tsx" /* webpackChunkName: "component---src-pages-overviews-owgtowg-tsx" */),
  "component---src-pages-overviews-slime-alchemist-tsx": () => import("./../../../src/pages/overviews/slime-alchemist.tsx" /* webpackChunkName: "component---src-pages-overviews-slime-alchemist-tsx" */),
  "component---src-pages-overviews-which-is-witch-tsx": () => import("./../../../src/pages/overviews/which-is-witch.tsx" /* webpackChunkName: "component---src-pages-overviews-which-is-witch-tsx" */)
}

